<template>
  <div id="user-view" class="" v-if="!loading">
    <v-row class="">
      <v-col cols="12" md="3" v-show="!userPanelHidden">
        <!-- <br> -->
        <UserBioPanel v-if="user" :user="user" class=""></UserBioPanel>
        <br />
        <!-- <v-card class="d-flex flex-column justify-center align-center">
          <v-card-text>
            <v-btn
              class=""
              color="secondary"
              block
              outlined
              @click="$router.go(-1)"
            >
              <v-icon>
                {{ icons.mdiArrowLeft }}
              </v-icon>
              <span class="ml-2">Volver</span>
            </v-btn>
          </v-card-text>
        </v-card> -->
      </v-col>

      <v-col cols="12" :md="userPanelHidden ? 12 : 9" class="">
        <v-tabs v-model="userTab" show-arrows class="user-tabs">
          <v-tab>
            <v-img
              :src="$vuetify.theme.isDark ? icons.tripWhite : icons.tripDark"
              class="icon-white"
            ></v-img>
            <span class="ml-2">{{ $t(tabs[0].title) }}</span>
          </v-tab>
          <v-tab v-if="is_active_shipments">
            <v-icon size="20" class="me-3">
              {{ tabs[1].icon }}
            </v-icon>
            <span>{{ $t(tabs[1].title) }}</span>
          </v-tab>
          <v-tab>
            <v-icon size="20" class="me-3">
              {{ tabs[2].icon }}
            </v-icon>
            <span>{{ $t(tabs[2].title) }}</span>
          </v-tab>
          <span
            class="d-flex flex-row justify-end align-center"
            :style="
              $vuetify.breakpoint.mdAndUp &&
              'position: absolute; top: 8px; right: 0'
            "
          >
            <span
              class="mr-2 mb-1"
              v-if="userPanelHidden && $vuetify.breakpoint.mdAndUp"
            >
              <v-avatar color="success" size="30">
                <span class="d-flex align-center justify-center">
                  <v-img
                    :src="`${bucketUrl}${user.picture}`"
                    :alt="user.first_name.charAt(0) + user.last_name.charAt(0)"
                    width="30"
                    v-if="user.picture"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row> </template
                  ></v-img>
                  <span v-else class="color-blue-text">
                    {{ user.first_name.charAt(0) + user.last_name.charAt(0) }}
                  </span>
                </span>
              </v-avatar>
              <span class="ml-1">
                {{ user.first_name + " " + user.last_name }}
                |
                <a
                  class="ml-2 mr-2"
                  :href="`tel:+58${user.phone}`"
                  >{{ user.phone }}</a
                >
                |
                <a
                  class="ml-2"
                  :href="`mailto:${user.email}`"
                  >{{ user.email }}</a
                >
              </span>
            </span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="userPanelHidden = !userPanelHidden"
                  class="mb-1"
                >
                  <v-icon large>
                    {{
                      userPanelHidden
                        ? icons.mdiToggleSwitchOutline
                        : icons.mdiToggleSwitchOffOutline
                    }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{
                !userPanelHidden ? $t("hide_user_panel") : $t("show_user_panel")
              }}</span>
            </v-tooltip>
          </span>
        </v-tabs>

        <v-tabs-items
          id="user-tabs-content"
          v-model="userTab"
          class="mt-5 pa-1"
          touchless
        >
          <v-tab-item class="">
            <UserTripPanel />
          </v-tab-item>
          <v-tab-item class="" v-if="is_active_shipments">
            <UserParcelPanel />
          </v-tab-item>

          <v-tab-item>
            <UserAssignPanel />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {
  mdiArrowLeft,
  mdiCurrencyUsd,
  mdiToggleSwitchOffOutline,
  mdiToggleSwitchOutline,
  mdiPackageVariantClosed
} from "@mdi/js";
import { mapActions, mapState } from "vuex";
import axios from "@/plugins/axios";
import UserBioPanel from "./parts/UserBioPanel.vue";
import tripWhite from "@/assets/Trip_white.svg";
import tripDark from "@/assets/Trip_dark.svg";
import UserTripPanel from "./parts/UserTripPanel.vue";
import UserParcelPanel from "./parts/UserParcelPanel.vue";
import UserAssignPanel from "./parts/UserAssignPanel.vue";

export default {
  components: {
    UserBioPanel,
    UserTripPanel,
    UserAssignPanel,
    UserParcelPanel
  },
  data() {
    return {
      icons: {
        mdiArrowLeft,
        tripWhite,
        tripDark,
        mdiCurrencyUsd,
        mdiPackageVariantClosed,
        mdiToggleSwitchOffOutline,
        mdiToggleSwitchOutline,
      },
      trips: {},
      user: false,
      userTab: null,
      tabs: [
        { icon: mdiArrowLeft, title: "Trips" },
        { icon: mdiPackageVariantClosed, title: "Parcels" },
        { icon: mdiCurrencyUsd, title: "Assignations" },
      ],
      notFound: false,
      userPanelHidden: false,
      bucketUrl: process.env.VUE_APP_IMAGE_BUCKET,
    };
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getUser() {
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_URL}/user/${this.$route.params.id}`
        );
        if (data.success) {
          this.user = data.user;
        }
      } catch (error) {
        this.notFound = true;
        this.$dialog.notify.error(
          "El usuario no existe o no es parte de este panel corporativo"
        );
        this.$router.push({
          name: "NotInPanel",
        });
      }
    },
  },
  computed: {
    ...mapState(["loading"]),
    ...mapState("auth", ["is_active_shipments"]),

  },
  created() {
    this.setLoading(true);
    this.getUser();
    this.setLoading(false);
  },
};
</script>
<style lang="scss">
#user-view {
  .user-tabs {
    &.v-tabs:not(.v-tabs--vertical) {
      box-shadow: none !important;
      .v-tabs-bar {
        background-color: transparent !important;
      }
    }
  }

  // tab content
  #user-tabs-content {
    background-color: transparent;
  }
}
</style>
