<template>
  <div>
    <v-row dense>
      <v-col cols="12">
        <v-card class="pa-5">
          <v-row dense>
            <v-col
              cols="12"
              class="d-flex flex-row justify-md-start justify-center"
            >
              <span class="d-flex flex-row justify-start align-center">
                <h2>{{ $t("Assignations") }}</h2>
                <v-tooltip top :max-width="300">
                  <template #activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      medium
                      v-on="on"
                      class="ml-6 color-gray-text"
                    >
                      {{ icons.mdiInformationOutline }}
                    </v-icon>
                  </template>
                  <span>
                    <!-- <ul> -->
                    <!-- <li> -->
                    {{ $t("tooltipUserAssign") }}
                    <!-- </li> -->
                    <!-- </ul> -->
                  </span>
                </v-tooltip>
              </span>
            </v-col>
          </v-row>

          <!-- Filtros -->
          <v-row dense v-if="false">
            <v-col cols="12" md="4">
              <v-select
                :label="$t('Transaction origin')"
                outlined
                dense
                clearable
                :items="[
                  `${$t('By admin')}`,
                  `${$t('User amount')}`,
                  `${$t('Charge of this trip')}`,
                  `${$t('Card')}`,
                ]"
                v-model="table.searchItem"
                :disabled="table.tableLoading"
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    :label="$t('Date range')"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    outlined
                    clearable
                    :append-icon="icons.mdiCalendar"
                    :disabled="table.tableLoading"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  range
                  no-title
                  scrollable
                  :max="computedMaxDate"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    {{ $t("Cancel") }}
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="2">
              <v-btn
                block
                color="primary"
                @click="getCorporatePaymentsFilter()"
                :disabled="table.tableLoading"
              >
                {{ $t("Apply") }}</v-btn
              ></v-col
            >
            <v-col
              cols="12"
              md="2"
              :class="!$vuetify.breakpoint.mdAndUp && 'mt-5'"
            >
              <v-btn
                block
                outlined
                color="primary"
                @click="getCorporatePaymentsExcelFilter()"
                :disabled="table.tableLoading"
              >
                <v-icon> {{ icons.mdiDownload }}</v-icon>
                <span class="ml-2">{{ $t("Export") }}</span>
              </v-btn></v-col
            >
          </v-row>

          <!-- tabla -->
          <v-row dense class="mt-4">
            <v-col cols="12">
              <DataTable
                :loading="table.tableLoading"
                :headers="table.headers"
                :items="computedTable"
                :page="table.currentPage"
                v-if="!new_"
              />
            </v-col>
          </v-row>

          <!-- <code>{{computedTable}}</code> -->

          <v-row
            dense
            v-if="!new_"
            class="d-flex flex-row justify-space-between align-center"
          >
            <v-col
              cols="12"
              md="4"
              class="d-flex flex-row justify-end align-center pt-8"
            >
              <v-select
                :label="$t('Results per page')"
                :items="[5, 10, 20, 30]"
                v-model="table.limit"
                :disabled="table.tableLoading"
                dense
                outlined
              >
              </v-select>
              <v-autocomplete
                :class="!$vuetify.mdAndUp && 'ml-2'"
                :label="$t('Go to page')"
                :items="Array.from(Array(table.pages + 1).keys()).slice(1)"
                v-model="table.currentPage"
                :disabled="table.tableLoading"
                dense
                outlined
                @input="table.currentPage && handlePageChange()"
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="d-flex flex-row justify-md-end justify-center align-start"
            >
              <!-- <code>currentPage: {{ table.currentPage }}</code>
              <code>Pages: {{ table.pages }}</code>
              <code>ComputedPage: {{ computedPage }}</code> -->
              <v-pagination
                v-model="table.currentPage"
                :length="table.pages"
                total-visible="12"
                @input="handlePageChange()"
                :disabled="table.tableLoading"
              ></v-pagination>
            </v-col>
          </v-row>

          <!-- Placeholder de nuevos -->
          <v-row v-if="new_" class="">
            <v-col
              cols="12"
              class="d-flex flex-column justify-center align-center"
            >
              <v-img
                v-if="!$vuetify.theme.isDark"
                src="../assets/NoAssignLight.png"
                max-width="250"
              ></v-img>
              <v-img
                v-else
                src="../assets/NoAssignDark.png"
                max-width="250"
              ></v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {
  mdiInformationOutline,
  mdiMagnify,
  mdiCalendar,
  mdiDotsVertical,
  mdiDownload,
  mdiPlus,
  mdiWallet,
  mdiWalletPlus,
} from "@mdi/js";
import axios from "@/plugins/axios";
import { mapActions, mapState } from "vuex";
import DataTable from "./partials/DataTableAssignUser.vue";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      icons: {
        mdiInformationOutline,
        mdiMagnify,
        mdiCalendar,
        mdiDotsVertical,
        mdiDownload,
        mdiPlus,
        mdiWallet,
        mdiWalletPlus,
      },
      date: [],
      menu: false,
      new_: false,
      table: {
        transactions: [],
        tableLoading: false,
        headers: [
          { text: "ID", value: "unique_id", align: "start" },
          { text: "FECHA Y HORA", value: "created_at", align: "start" },
          {
            text: "BALANCE INICIAL (CORPORATIVO)",
            value: "wallet_amount",
            align: "end",
          },
          {
            text: "AGREGADO/RESTADO (USUARIO)",
            value: "add_cut",
            align: "end",
          },

          {
            text: "BALANCE FINAL (CORPORATIVO)",
            value: "total_wallet_amount",
            align: "end",
          },
          {
            text: "ORIGEN DE TRANSACCIÓN",
            value: "wallet_description",
            align: "start",
          },
        ],
        currentPage: 1,
        pages: 1,
        searchItem: null,
        limit: this.$vuetify.breakpoint.mdAndUp ? 10 : 5,
        search: "",
      },
    };
  },
  methods: {
    ...mapActions("auth", ["meNotLoading"]),
    ...mapActions(["setLoading"]),

    async getCorporatePayments() {
      this.table.tableLoading = true;
      let params = {
        page: this.computedPage,
        limit: this.table.limit,
      };
      if (this.table.searchItem) {
        let search_item = "";
        if (this.table.searchItem === this.$t("By admin")) {
          search_item = 1;
        }
        if (this.table.searchItem === this.$t("User amount")) {
          search_item = 2;
        }
        if (this.table.searchItem === this.$t("Charge of this trip")) {
          search_item = 3;
        }
        if (this.table.searchItem === this.$t("Card")) {
          search_item = 4;
        }
        params["transaction_type"] = search_item;
      }
      if (this.date) {
        if (this.date.length >= 1) {
          let sorted = this.date.sort();
          params["start_date"] = `${sorted[0]}`;
          if (sorted.length === 1) {
            params["end_date"] = `${sorted[0]}`;
          }
          if (sorted.length > 1) {
            params["end_date"] = `${sorted[1]}`;
          }
        }
      }
      try {
        const { data } = await axios.get(
          `/user_transactions/${this.$route.params.id}`,
          {
            params: params,
          }
        );
        if (data) {
          this.table.transactions = [];
          data.transactions.forEach((trip) => {
            // trip = this.handleOriginTranslate(trip);
            this.table.transactions.push(trip);
          });
          this.table.pages = data.pages;
          this.table.currentPage = parseFloat(data.current_page) + 1;
          if (data.transactions.length >= 1) {
            this.new_ = false;
          } else {
            this.new_ = true;
          }
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.meNotLoading();

      this.table.tableLoading = false;
    },
    async getCorporatePaymentsFilter() {
      this.table.currentPage = 1;
      this.table.tableLoading = true;
      let params = {
        page: this.computedPage,
        limit: this.table.limit,
      };
      if (this.table.searchItem) {
        let search_item = "";
        if (this.table.searchItem === this.$t("By admin")) {
          search_item = 1;
        }
        if (this.table.searchItem === this.$t("User amount")) {
          search_item = 2;
        }
        if (this.table.searchItem === this.$t("Charge of this trip")) {
          search_item = 3;
        }
        if (this.table.searchItem === this.$t("Card")) {
          search_item = 4;
        }
        params["transaction_type"] = search_item;
      }
      if (this.date) {
        if (this.date.length >= 1) {
          let sorted = this.date.sort();
          params["start_date"] = `${sorted[0]}`;
          if (sorted.length === 1) {
            params["end_date"] = `${sorted[0]}`;
          }
          if (sorted.length > 1) {
            params["end_date"] = `${sorted[1]}`;
          }
        }
      }
      try {
        const { data } = await axios.get(
          `/user_transactions/${this.$route.params.id}`,
          {
            params: params,
          }
        );
        if (data) {
          this.table.transactions = [];
          data.detail.forEach((trip) => {
            // trip = this.handleOriginTranslate(trip);
            this.table.transactions.push(trip);
          });
          this.table.pages = data.pages;
          this.table.currentPage = parseFloat(data.current_page) + 1;
          if (data.detail.length >= 1) {
            this.new_ = false;
          } else {
            this.new_ = true;
          }
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.meNotLoading();

      this.table.tableLoading = false;
    },
    async getCorporatePaymentsExcelFilter() {
      this.setLoading(true);
      let params = {};
      if (this.table.searchItem) {
        let search_item = "";
        if (this.table.searchItem === this.$t("By admin")) {
          search_item = 1;
        }
        if (this.table.searchItem === this.$t("User amount")) {
          search_item = 2;
        }
        if (this.table.searchItem === this.$t("Charge of this trip")) {
          search_item = 3;
        }
        if (this.table.searchItem === this.$t("Card")) {
          search_item = 4;
        }
        params["transaction_type"] = search_item;
      }
      if (this.date) {
        if (this.date.length >= 1) {
          let sorted = this.date.sort();
          params["start_date"] = `${sorted[0]}`;
          if (sorted.length === 1) {
            params["end_date"] = `${sorted[0]}`;
          }
          if (sorted.length > 1) {
            params["end_date"] = `${sorted[1]}`;
          }
        }
      }
      try {
        const { data } = await axios.post(
          "/generate_payment",
          {},
          {
            params: params,
          }
        );
        if (data.success) {
          window.open(data.url, "_blank").focus();
        } else {
          throw "Error";
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.meNotLoading();
      this.setLoading(false);
    },
    handlePageChange() {
      this.getCorporatePayments();
    },
    handleOriginTranslate(trip) {
      trip.wallet_description = trip.wallet_description.replace(
        "By Admin",
        "Por admin"
      );
      trip.wallet_description = trip.wallet_description.replace(
        "User amount limit",
        "Monto usuario"
      );
      trip.wallet_description = trip.wallet_description.replace(
        "Charge Of This Trip",
        "Cargo del viaje"
      );
      trip.wallet_description = trip.wallet_description.replace(
        "Card",
        "Tarjeta"
      );
      trip.wallet_description = trip.wallet_description.replace(
        "Charge Of This Parcel",
        "Cargo del envío"
      );
      return trip;
    },
  },
  created() {
    this.getCorporatePayments();
  },
  computed: {
    ...mapState("auth", ["wallet", "overdraft"]),
    computedIconStyle() {
      if (this.wallet < 0) {
        return "color: #ef4370 !important;";
      }
      if (this.wallet === 0) {
        return "color: #000522 !important;";
      }
      if (this.wallet > 0) {
        return "color: #3BD4AE !important;";
      }
    },
    computedColor() {
      if (this.wallet < 0) {
        return "color-red-text";
      }
      if (this.wallet === 0) {
        return "color-blue-text";
      }
      if (this.wallet > 0) {
        return "color-green-text";
      }
    },
    computedButtonColor() {
      if (this.wallet < 0) {
        return "accent";
      }
      if (this.wallet === 0) {
        return "primary";
      }
      if (this.wallet > 0) {
        return "success";
      }
    },
    computedBackground() {
      if (this.wallet < 0) {
        return "salmon-background";
      }
      if (this.wallet === 0) {
        return "blue-background";
      }
      if (this.wallet > 0) {
        return "green-background";
      }
    },
    computedTable() {
      return this.table.transactions;
    },
    computedPage() {
      return this.table.currentPage - 1;
    },
    computedMaxDate() {
      let today = new Date();
      // utc-4 ccs
      return this.$moment(today).add(-4, "hours").toISOString();
    },
  },
  watch: {
    "table.limit": function (oldVal, newVal) {
      if (oldVal > newVal) {
        this.table.currentPage = 1;
      }
      this.getCorporatePayments();
    },
    "table.currentPage": function (newVal, oldVal) {
      if (!newVal) {
        this.table.currentPage = oldVal;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.salmon-background {
  background-color: rgba(239, 67, 112, 0.08);
}
.blue-background {
  background-color: rgb(0, 5, 34, 0.08);
}
.green-background {
  background-color: rgb(59, 212, 174, 0.08);
}
.custom-font-size {
  font-size: 48px;
}
.custom-font-size-small {
  font-size: 24px;
}
.custom-margin {
  margin-top: -10px;
}
.custom-margin-2 {
  margin-top: 75px !important;
}
a {
  text-decoration: none;
}
</style>
