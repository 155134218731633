<template>
  <div>
    <v-row dense>
      <v-col cols="12">
        <v-card class="pa-5">
          <v-row dense>
            <v-col
              cols="12"
              class="d-flex flex-row justify-md-start justify-center"
            >
              <span class="d-flex flex-row justify-start align-center">
                <h2>{{ $t("Parcel history") }}</h2>
                <v-tooltip top :max-width="300">
                  <template #activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      medium
                      v-on="on"
                      class="ml-6 color-gray-text"
                    >
                      {{ icons.mdiInformationOutline }}
                    </v-icon>
                  </template>
                  <span>
                    <!-- <ul> -->
                    <!-- <li> -->
                    {{ $t("ParceltooltipHistoryUser") }}
                    <!-- </li> -->
                    <!-- </ul> -->
                  </span>
                </v-tooltip>
              </span>
            </v-col>
          </v-row>

          <!-- Filtros -->
          <v-row dense class="mt-4" v-if="false">
            <v-col cols="12" md="2">
              <v-select
                :label="$t('Search by...')"
                outlined
                dense
                :items="[`${$t('unique_id')}`]"
                v-model="table.searchItem"
                clearable
                :disabled="table.tableLoading"
              ></v-select
            ></v-col>
            <v-col cols="12" md="3">
              <v-text-field
                :label="$t('Search')"
                :prepend-inner-icon="icons.mdiMagnify"
                outlined
                dense
                v-model="table.search"
                :disabled="!table.searchItem"
                clearable
                @keyup.esc="table.search = ''"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    :label="$t('Date range')"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    outlined
                    clearable
                    :append-icon="icons.mdiCalendar"
                    :disabled="table.tableLoading"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  range
                  no-title
                  scrollable
                  :max="computedMaxDate"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    {{ $t("Cancel") }}
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu></v-col
            >
            <!-- {{date.length < 1}} -->
            <v-col cols="12" md="2">
              <v-btn
                block
                color="primary"
                @click="getHistoryFilter()"
                :disabled="table.tableLoading"
              >
                {{ $t("Apply") }}</v-btn
              ></v-col
            >
            <v-col
              cols="12"
              md="2"
              :class="!$vuetify.breakpoint.mdAndUp && 'mt-5'"
            >
              <v-btn
                block
                outlined
                color="primary"
                :disabled="table.tableLoading"
              >
                <!-- @click="getHistoryFilterExcel()" -->
                <v-icon> {{ icons.mdiDownload }}</v-icon>
                <span class="ml-2">{{ $t("Export") }}</span>
              </v-btn></v-col
            >
          </v-row>

          <!-- tabla -->
          <v-row dense class="mt-4">
            <v-col cols="12">
              <DataTable
                :loading="table.tableLoading"
                :headers="table.headers"
                :items="computedTable"
                :page="table.currentPage"
                v-if="!new_"
              />
              <!-- v-if="!new_" -->
            </v-col>
          </v-row>

          <!-- <code>{{computedTable}}</code> -->

          <v-row
            dense
            v-if="!new_"
            class="d-flex flex-row justify-space-between align-center"
          >
            <v-col
              cols="12"
              md="4"
              class="d-flex flex-row justify-end align-center pt-8"
            >
              <v-select
                :label="$t('Results per page')"
                :items="[5, 10, 20, 30]"
                v-model="table.limit"
                :disabled="table.tableLoading"
                dense
                outlined
              >
              </v-select>
              <v-autocomplete
                :class="!$vuetify.mdAndUp && 'ml-2'"
                :label="$t('Go to page')"
                :items="Array.from(Array(table.pages + 1).keys()).slice(1)"
                v-model="table.currentPage"
                :disabled="table.tableLoading"
                dense
                outlined
                @input="table.currentPage && handlePageChange()"
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="d-flex flex-row justify-md-end justify-center align-start"
            >
              <!-- <code>currentPage: {{ table.currentPage }}</code>
              <code>Pages: {{ table.pages }}</code>
              <code>ComputedPage: {{ computedPage }}</code> -->
              <v-pagination
                v-model="table.currentPage"
                :length="table.pages"
                total-visible="12"
                @input="handlePageChange()"
                :disabled="table.tableLoading"
              ></v-pagination>
            </v-col>
          </v-row>

          <v-row v-if="new_">
            <v-col cols="12" class="d-flex justify-center align-center">
              <v-img
                v-if="!$vuetify.theme.isDark"
                src="../assets/NoParcelLight.png"
                max-width="250"
              ></v-img>
              <v-img
                v-else
                src="../assets/NoParcelDark.png"
                max-width="250"
              ></v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {
  mdiInformationOutline,
  mdiMagnify,
  mdiCalendar,
  mdiDotsVertical,
  mdiDownload,
} from "@mdi/js";
import axios from "@/plugins/axios";
import { mapActions, mapState } from "vuex";
import DataTable from "./partials/DataTableParcelsUser.vue";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      icons: {
        mdiInformationOutline,
        mdiMagnify,
        mdiCalendar,
        mdiDotsVertical,
        mdiDownload,
      },
      date: [],
      menu: false,
      new_: false,
      table: {
        trips: [],
        tableLoading: false,
        headers: [
          { text: "ID VIAJE", value: "unique_id", align: "center" },
          { text: "CONDUCTOR", value: "provider_name", align: "start" },
          { text: "FECHA Y HORA", value: "date", align: "start", width: 100 },
          { text: "COSTO", value: "total", align: "end" },
          {
            text: "SERVICIO",
            value: "serviceType",
            align: "center",
            sortable: false,
          },
          {
            text: "DIRECCIÓN DE ORIGEN",
            value: "source_address",
            align: "start",
            width: 140,
          },
          {
            text: "DIRECCIÓN DE DESTINO",
            value: "destination_address",
            align: "start",
            width: 140,
          },
          { text: "STATUS", value: "trip_status", align: "center" },
          { text: "", value: "data-table-expand" },
          // {
          //   text: "ACCIÓN",
          //   value: "actions",
          //   sortable: false,
          //   align: "center",
          // },
        ],
        currentPage: 1,
        pages: 5,
        searchItem: null,
        limit: this.$vuetify.breakpoint.mdAndUp ? 10 : 5,
        search: "",
      },
    };
  },
  methods: {
    ...mapActions(["setLoading"]),

    async getHistory() {
      this.table.tableLoading = true;
      let params = {
        page: this.computedPage,
        limit: this.table.limit,
        only_shipments: 1
      };
      if (this.table.search) {
        let search_item = "";
        if (this.table.searchItem === "ID") {
          search_item = "unique_id";
        }
        if (
          this.table.searchItem === "Nombre" ||
          this.table.searchItem === "Name"
        ) {
          search_item = "user_first_name";
        }
        if (this.table.search.length >= 1 && this.table.searchItem) {
          params["search_item"] = search_item;
          params["search_value"] =
            this.table.searchItem === "unique_id"
              ? parseFloat(this.table.search)
              : this.table.search;
        }
      }
      if (this.date) {
        if (this.date.length >= 1) {
          let sorted = this.date.sort();
          params["start_date"] = `${sorted[0]}`;
          if (sorted.length === 1) {
            params["end_date"] = `${sorted[0]}`;
          }
          if (sorted.length > 1) {
            params["end_date"] = `${sorted[1]}`;
          }
        }
      }
      try {
        const { data } = await axios.get(
          `/user_trips/${this.$route.params.id}`,
          { params: params }
        );
        console.log(data);
        if (data["trips"]) {
          this.table.trips = [];
          data.trips.forEach((trip) => {
            trip["date"] =
              trip.server_start_time_for_schedule || trip.created_at;
            this.table.trips.push(trip);
          });
          this.table.pages = data.pages;
          this.table.currentPage = parseFloat(data.current_page) + 1;
          this.new_ = false;
        } else {
          this.new_ = true;
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.table.tableLoading = false;
    },
    handlePageChange() {
      this.getHistory();
    },
    async getHistoryFilter() {
      this.table.currentPage = 1;
      this.table.tableLoading = true;
      let params = {
        page: this.computedPage,
        limit: this.table.limit,
      };
      if (this.table.search) {
        let search_item = "";
        if (this.table.searchItem === "ID") {
          search_item = "unique_id";
        }
        if (
          this.table.searchItem === "Nombre" ||
          this.table.searchItem === "Name"
        ) {
          search_item = "user_first_name";
        }
        if (this.table.search.length >= 1 && this.table.searchItem) {
          params["search_item"] = search_item;
          params["search_value"] =
            this.table.searchItem === "unique_id"
              ? parseFloat(this.table.search)
              : this.table.search;
        }
      }
      if (this.date) {
        if (this.date.length >= 1) {
          let sorted = this.date.sort();
          params["start_date"] = `${sorted[0]}`;
          if (sorted.length === 1) {
            params["end_date"] = `${sorted[0]}`;
          }
          if (sorted.length > 1) {
            params["end_date"] = `${sorted[1]}`;
          }
        }
      }
      try {
        const { data } = await axios.get(
          `/user_trips/${this.$route.params.id}`,
          { params: params }
        );
        console.log(data.trips.length);
        if (data.trips.length >= 1) {
          this.table.trips = [];
          data.trips.forEach((trip) => {
            trip["provider_name"] =
              trip.provider_first_name + " " + trip.provider_last_name;
            this.table.trips.push(trip);
          });
          this.table.pages = data.pages;
          this.table.currentPage = parseFloat(data.current_page) + 1;
          this.new_ = false;
        } else {
          this.new_ = true;
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.table.tableLoading = false;
    },
    async getHistoryFilterExcel() {
      this.setLoading(true);
      let params = {};
      if (this.table.search) {
        let search_item = "";
        if (this.table.searchItem === "ID") {
          search_item = "unique_id";
        }
        if (
          this.table.searchItem === "Nombre" ||
          this.table.searchItem === "Name"
        ) {
          search_item = "user_first_name";
        }
        if (this.table.search.length >= 1 && this.table.searchItem) {
          params["search_item"] = search_item;
          params["search_value"] =
            this.table.searchItem === "unique_id"
              ? parseFloat(this.table.search)
              : this.table.search;
        }
      }
      if (this.date) {
        if (this.date.length >= 1) {
          let sorted = this.date.sort();
          params["start_date"] = `${sorted[0]}`;
          if (sorted.length === 1) {
            params["end_date"] = `${sorted[0]}`;
          }
          if (sorted.length > 1) {
            params["end_date"] = `${sorted[1]}`;
          }
        }
      }
      try {
        const { data } = await axios.post(
          "/generate_requests",
          {},
          { params: params }
        );
        if (data.success) {
          window.open(data.url, "_blank").focus();
        } else {
          throw "Error";
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.setLoading(false);
    },
  },
  computed: {
    computedPage() {
      return this.table.currentPage - 1;
    },
    computedTable() {
      return this.table.trips;
    },
    computedMaxDate() {
      let today = new Date();
      // utc-4 ccs
      return this.$moment(today).add(-4, "hours").toISOString();
    },
  },
  created() {
    this.getHistory();
  },
  watch: {
    "table.limit": function (oldVal, newVal) {
      if (oldVal > newVal) {
        this.table.currentPage = 1;
      }
      this.getHistory();
    },
    "table.currentPage": function (newVal, oldVal) {
      if (!newVal) {
        this.table.currentPage = oldVal;
      }
    },
  },
};
</script>
<style lang="scss"></style>
