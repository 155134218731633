<template>
  <v-row>
    <v-col cols="12">
      <v-card class="card_">
        <div class="avatar">
          <v-avatar class="avatar-image" color="info" size="110" contain tile>
            <span
              v-if="user.picture"
              class="d-flex align-center justify-center"
            >
              <v-img
                :src="`${bucketUrl}${user.picture}`"
                :max-width="110"
                :alt="user.first_name[0] + user.last_name[0]"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row> </template
              ></v-img>
            </span>
            <span v-else class="white--text text-h4">{{ user.first_name !== undefined && user.last_name !== undefined &&
              user.first_name.charAt(0) + user.last_name.charAt(0)
            }}</span>
          </v-avatar>
        </div>
        <v-card-title class="justify-center flex-column mt-12">
          <span class="mb-1">{{ user.first_name + " " + user.last_name }}</span>
        </v-card-title>
        <v-card-text class="d-flex flex-column">
          <h2 class="text-xl font-weight-semibold mb-2">{{ $t("Details") }}</h2>
          <v-divider></v-divider>
          <v-list>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Status:</span>
              <span class="text--secondary">
                <v-chip
                  small
                  label
                  :color="Boolean(user.is_approved) ? 'success' : 'error'"
                  class="v-chip-light-bg"
                  :class="
                    Boolean(user.is_approved) ? 'success--text' : 'error--text'
                  "
                >
                  {{
                    Boolean(user.is_approved)
                      ? $t("Approved")
                      : $t("Not approved")
                  }}
                </v-chip>
              </span>
            </v-list-item>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2"
                >{{ $t("Overdraft") }}:</span
              >
              <span class="text--secondary">
                <v-chip
                  small
                  label
                  :color="
                    Boolean(user.is_allow_overdraft) ? 'success' : 'error'
                  "
                  class="v-chip-light-bg"
                  :class="
                    Boolean(user.is_allow_overdraft)
                      ? 'success--text'
                      : 'error--text'
                  "
                >
                  {{
                    Boolean(user.is_allow_overdraft)
                      ? $t("Active")
                      : $t("Inactive")
                  }}
                </v-chip>
              </span>
            </v-list-item>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">{{ $t("user_wallet") }}:</span>
              <span :class="tableCellBalance(user.corporate_wallet_limit)">{{
                user.corporate_wallet_limit | currency
              }}</span>
            </v-list-item>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Email:</span>
              <span class="text-caption"
                ><a :href="`mailto:${user.email}`">{{ user.email }}</a></span
              >
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2"
                >{{ $t("Telephone") }}:</span
              >
              <span class="text--secondary"
                ><a :href="`tel:${user.country_phone_code}${user.phone}`">{{
                  user.country_phone_code + user.phone
                }}</a></span
              >
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mdiCheck, mdiClose } from "@mdi/js";

export default {
  props: {
    user: {
      type: [Object, Boolean],
      required: true,
    },
  },
  data() {
    return {
      icons: {
        mdiCheck,
        mdiClose,
      },
      bucketUrl: process.env.VUE_APP_IMAGE_BUCKET,
    };
  },
  methods: {
    tableCellBalance(value) {
      if (value >= 0.01) {
        return `color-green-text`;
      }
      if (value < 0) {
        return `color-red-text`;
      }
      return `text--secondary`;
    },
  },
};
</script>
<style lang="scss" scoped>
.avatar {
  background-color: #3bd4ae;
  min-height: 100px;
  position: relative;
  &-image {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 50%;
    text-align: center;
  }
}
.card_ {
  min-height: 300px;
}
</style>
